import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogPost = ({ data, location }) => {
  const post = data.markdownRemark
  console.log(post)
  const HeaderImage = ({ headerImage, headerImageDescription }) =>
    headerImage ? (
      <GatsbyImage
        image={headerImage.childImageSharp.gatsbyImageData}
        alt={headerImageDescription}
      ></GatsbyImage>
    ) : (null)
  return (
    <Layout location={location}>
      <SEO title={post.frontmatter.title} />

      <small>{post.frontmatter.date}</small>
      <h1>{post.frontmatter.title}</h1>
      <HeaderImage
        headerImage={post.frontmatter.headerImage}
        headerImageDescription={post.frontmatter.headerImageDescription}
      ></HeaderImage>

      <div
        style={{ marginTop: "1rem" }}
        dangerouslySetInnerHTML={{ __html: post.html }}
      />
    </Layout>
  )
}

export default BlogPost

export const query = graphql`
  query BlogQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        headerImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        headerImageDescription
      }
    }
  }
`
